<template>
  <div>
    <Notify :showNotice = "showNotice" :messErr = "messErr" :messageNotice="messageNotice"></Notify>
    <div class="flex flex-wrap mt-4 table-main">
      <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded"
           :class="[color === 'light' ? 'bg-white' : 'bg-emerald-900 text-white']">
        <div class="rounded-t mb-0 px-4 py-3 border-0">
          <div class="flex flex-wrap items-center" style="justify-content: space-between;">
            <div class="relative px-4 max-w-full">
              <h3 class="font-semibold text-lg" :class="[color === 'light' ? 'text-blueGray-700' : 'text-white']">
                Thống kê theo Merchant
              </h3>
            </div>
          </div>
        </div>
        <div class="block w-full overflow-x-auto">
          <div class="flex flex-wrap p-5 mb-4">
            <div class="mr-3 width16">
              <label class="block text-gray-700 text-sm font-bold mb-2" for="merchant_name">
                Tên merchant
              </label>
              <multiselect v-model="formData.name" :searchable="true" :options="options" :loading="isLoading" @search-change="getAllMerchants" class="w-full text-gray-700"></multiselect>
            </div>
            <div class="mr-3">
              <label class="block text-gray-700 text-sm font-bold mb-2" for="merchant_name">
                Trạng thái hoạt động
              </label>
              <select v-model="formData.status">
                <option value="1">Đang hoạt động</option>
                <option value="0">Không hoạt động</option>
              </select>
            </div>
            <div class="mr-3">
              <label class="block text-gray-700 text-sm font-bold mb-2" for="merchant_name">
                Loại giao dịch
              </label>

              <select v-model="formData.action">
                <option value="">Tất cả</option>
                <option v-for="type in transactionType" :key="type.value" :value="type.value">{{ type.label }}</option>
              </select>
            </div>
            <div class="mr-3">
              <label class="block text-gray-700 text-sm font-bold mb-2" for="merchant_name">
                Từ ngày
              </label>
              <input v-model="formData.date_from" type="date" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="merchant_name" placeholder="Tất cả">
            </div>
            <div>
              <label class="block text-gray-700 text-sm font-bold mb-2" for="merchant_name">
                Đến ngày
              </label>
              <input v-model="formData.date_to" type="date" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="merchant_name" placeholder="Tất cả">
            </div>
          </div>
          <div class="text-right p-5">
            <button type="button" v-on:keyup.enter="fetchData()" @click="fetchData()" class="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"><i class="fa fa-search"></i> Search</button>
          </div>

          <div class="px-3">
            <!-- Projects table -->
            <table class="table-auto w-full border-collapse">
              <thead>
              <tr>
                <th
                    class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                    :class="[
                    color === 'light'
                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                  ]">
                  STT
                </th>
                <th
                    class="border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                    :class="[
                    color === 'light'
                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                  ]">
                  Tên merchant
                </th>
                <th
                    class="border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                    :class="[
                    color === 'light'
                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                  ]">
                  Trạng thái
                </th>
                <th
                    class="border border-solid py-3 text-xs uppercase border-l-0 border-r-0 font-semibold text-left"
                    :class="[
                    color === 'light'
                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                  ]">
                  Ngày đăng ký
                </th>

                <th
                    class="border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-right"
                    :class="[
                    color === 'light'
                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                  ]">
                  Tổng user
                </th>
                <th
                    class="border border-solid py-3 text-xs uppercase border-l-0 border-r-0 font-semibold text-right"
                    :class="[
                    color === 'light'
                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                  ]">
                  New users trong ngày
                </th>
                <th
                    class="border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-right"
                    :class="[
                    color === 'light'
                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                  ]">
                  Số lượng giao dịch
                </th>
                <th
                    class="border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-right"
                    :class="[
                    color === 'light'
                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                  ]">
                  Tổng GMV
                </th>
                <th
                    class="border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-right"
                    :class="[
                    color === 'light'
                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                  ]">
                  Phí trả 9PAY
                </th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, index) in dataTable" :key="index">
                <td
                    class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                    <span class="ml-3 font-bold" :class="[
                      color === 'light' ? 'text-blueGray-600' : 'text-white',
                    ]">
                      {{ index + 1 }}
                    </span>
                </td>
                <td
                    class="border-t-0 border-l-0 border-r-0 text-xs whitespace-nowrap">
                    <span class="font-bold" :class="[
                      color === 'light' ? 'text-blueGray-600' : 'text-white',
                    ]">
                      {{ item.name }}
                    </span>
                </td>
                <td
                    class="border-t-0 border-l-0 border-r-0 text-xs whitespace-nowrap">
                    <span class="font-bold" :class="[
                      item.status === 1 ? 'text-green-600' : 'text-red-600',
                    ]">
                      {{ item.status === 1 ? 'Hoạt động' : 'Không hoạt động' }}
                    </span>
                </td>
                <td
                    class="border-t-0 border-l-0 border-r-0 text-xs whitespace-nowrap">
                    <span class="font-bold" :class="[
                      color === 'light' ? 'text-blueGray-600' : 'text-white',
                    ]">
                      {{ convertDate(String(item.created_at), 'DD/MM/YYYY') }}
                    </span>
                </td>
                <td
                    class="border-t-0 border-l-0 border-r-0 text-xs whitespace-nowrap text-right">
                    <span class="font-bold" :class="[
                      color === 'light' ? 'text-blueGray-600' : 'text-white',
                    ]">
                      ---
                    </span>
                </td>
                <td
                    class="border-t-0 border-l-0 border-r-0 text-xs whitespace-nowrap text-right">
                    <span class="font-bold" :class="[
                      color === 'light' ? 'text-blueGray-600' : 'text-white',
                    ]">
                      ---
                    </span>
                </td>
                <td
                    class="border-t-0 border-l-0 border-r-0 text-xs whitespace-nowrap text-right">
                    <span class="font-bold" :class="[
                      color === 'light' ? 'text-blueGray-600' : 'text-white',
                    ]">
                      {{ item.transaction > 0 ? Number(item.transaction).toLocaleString() : item.transaction }}
                    </span>
                </td>
                <td
                    class="border-t-0 border-l-0 border-r-0 text-xs whitespace-nowrap text-right">
                    <span class="font-bold" :class="[
                      color === 'light' ? 'text-blueGray-600' : 'text-white',
                    ]">
                      {{ item.gmv > 0 ? Number(item.gmv).toLocaleString() : item.gmv }}
                    </span>
                </td>
                <td
                    class="border-t-0 border-l-0 border-r-0 text-xs whitespace-nowrap text-right">
                    <span class="font-bold" :class="[
                      color === 'light' ? 'text-blueGray-600' : 'text-white',
                    ]">
                      ---
                    </span>
                </td>
              </tr>
              </tbody>
              <tfoot style="height: 50px;">
              <Pagination :dataPage="dataUser" @pageChange:index="fetchData" />
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<style>
.table-main table tbody {
  font-size: 22px;
  font-weight: 500;
}

.main-boss {
  display: grid;
  grid-template-rows: 0fr;
}

.overlay-1 {
  opacity: 0.3;
  transition: opacity 0.3s ease;
}

.overlay-2 {
  opacity: 0.6;
  transition: opacity 0.3s ease;
}
.multiselect__tags {
  border-color: #71717a !important;
}
</style>
<script>
import Pagination from "@/components/Panigation.vue";
import bootstrap from "@/assets/img/bootstrap.jpg";
import angular from "@/assets/img/angular.jpg";
import sketch from "@/assets/img/sketch.jpg";
import react from "@/assets/img/react.jpg";
import vue from "@/assets/img/react.jpg";
import apiClient from '@/services/api.js';
import Notify from "@/components/Notification/Notify.vue"
import moment from 'moment';
import Multiselect from 'vue-multiselect'


export default {
  data() {
    return {
      bootstrap,
      angular,
      sketch,
      react,
      vue,
      showModal: false,
      dataTable: [],
      dataUser: [],
      formData: {
        name: null,
        status: 1,
        action: '',
        date_from: moment().subtract(30, "days").format('YYYY-MM-DD'),
        date_to: moment().format('YYYY-MM-DD'),
      },
      form: '',
      itemId: '',
      progress: 0, // Giả sử bạn có một biến progress để theo dõi tiến trình
      limit: 10,
      page: null,
      messageNotice: '',
      messErr: false,
      showNotice: false,
      transactionType: [
        {value: 'DEPOSIT', label: 'Nạp tiền'},
        {value: 'PAYMENT', label: 'Thanh toán'},
        {value: 'WITHDRAW', label: 'Rút tiền'},
        {value: 'TRANSFER', label: 'Chuyển tiền'},
      ],
      options: [],
      isLoading: false
    };
  },
  components: {
    Pagination,
    Notify,
    Multiselect
  },
  props: {
    color: {
      default: "light",
      validator: function (value) {
        // The value must match one of these strings
        return ["light", "dark"].indexOf(value) !== -1;
      },
    },
  },
  created() {
    this.fetchData();
    this.getAllMerchants();
    window.addEventListener('keydown', this.handleKeyDown);
  },
  methods: {
    handleKeyDown(e) {
      if (e.keyCode === 13) {
        this.fetchData();
      }
    },
    getAllMerchants(query = "") {
      this.isLoading = true;
      apiClient.get('api/cms/merchant/list-all', {params: {name: query}})
          .then(response => {
            let merchants = [];
            let result = response.data.data;

            result.forEach(function (item) {
              merchants.push(item.name)
            });

            this.options = merchants;
            this.isLoading = false
          })
          .catch(error => {
            console.error('Đã có lỗi xảy ra!', error);
            this.isLoading = false
          });
    },
    fetchData(value = 1) {
      this.formData.page = value;

      if (!this.checkDate()) {
        return false;
      }

      apiClient.get('api/cms/merchant/analytics/transaction', {params: this.formData})
        .then(response => {
          this.dataTable = response.data.data.data;
          this.dataUser = response.data.data;
        })
        .catch(error => {
          console.error('Đã có lỗi xảy ra!', error);
        });
    },

    convertDate(value, format = 'DD/MM/YYYY hh:mm:ss') {
      if (value) {
        return moment(String(value)).format(format)
      }

      return value;
    },
    checkDate() {
      this.showNotice = false;

      if (!this.formData.date_from || !this.formData.date_to) {
        this.showNotice = true;
        this.messErr = 'Phải nhập đầy đủ thời gian tìm kiếm'
        return false;
      }

      let dateFrom = moment(String(this.formData.date_from));
      let dateTo = moment(String(this.formData.date_to));

      if (!dateFrom.isBefore(dateTo) && !dateFrom.isSame(dateTo)) {
        this.showNotice = true;
        this.messErr = 'Từ thời gian không được lớn hơn Đến thời gian'
        return false;
      }

      return true;
    }
  },
};
</script>
