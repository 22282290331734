<template>
  <div class="py-2 main-panigation">
    <nav class="">
      <ul class="flex pl-0 rounded list-none flex-wrap">
        <li>
          <a @click="changePagePaginate(1)"
            class="first:ml-0 cursor-pointer text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-emerald-500 bg-white text-emerald-500">
            <i class="fas fa-chevron-left -ml-px"></i>
          </a>
        </li>
        <li v-for="link in paginationLinks" :key="link.label">
          <a @click="changePagePaginate(link.label)" v-if="link.url"
            class="first:ml-0 cursor-pointer text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-emerald-500 bg-white " :class="[link.active ? 'bg-emerald-500 text-white' : 'text-emerald-500' ]">
            {{ link.label }}
          </a>
          <span v-else>
            {{ link.label }}
          </span>
        </li>
        <li>
          <a @click="changePagePaginate(dataPage.last_page)"
            class="first:ml-0 cursor-pointer text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-emerald-500 bg-white text-emerald-500">
            <i class="fas fa-chevron-right -mr-px"></i>
          </a>
        </li>
      </ul>
    </nav>
  </div>
</template>
<style>
  .main-panigation{
    position: absolute;
    bottom: 0;
    right: 30px;
    padding: 16px 0px;
  }
  .main-panigation ul li{
    padding: 0px 2px;
  }
</style>
<script>
//   import CardStats from "@/components/Cards/CardStats.vue";

export default {
  data() {
    return {
      data: {},
      currentPage: 1,
      perPage: 5, // số lượng item trên mỗi trang
    };
  },
  props: {
    dataPage: Object, // Truyền dữ liệu phân trang từ parent component
  },
  computed: {
    paginationLinks() {
      if (this.dataPage.links && this.dataPage.links.length > 2) {
        let dataRes = this.dataPage.links.slice(1, -1);
        return dataRes;
      }
      return this.dataPage.links;
    },
  },
  methods: {
    changePagePaginate: function (page) {
      if (page) {
        this.$emit('pageChange:index', page);
      }
    },
  },
};
</script>
