import {createApp} from "vue";
import App from "@/App.vue";
import router from "@/router"; // Import router từ file router.js
import gAuthPlugin from "vue3-google-oauth2";
import store from "@/services/store.js";
import Treeselect from 'vue3-treeselect'
import Notify from "@/components/Notification/Notify.vue"

// styles
import "@fortawesome/fontawesome-free/css/all.min.css";
import "@/assets/styles/tailwind.css";
import "@/assets/styles/app.css";
import 'vue3-treeselect/dist/vue3-treeselect.css'

const app = createApp(App);

let gauthClientId = "289772124438-c597q87evmu5k4ft5c2h640po8d0v4l2.apps.googleusercontent.com";
app.use(gAuthPlugin, {
    clientId: gauthClientId,
    scope: "email",
    prompt: "consent",
    fetch_basic_profile: false,
    plugin_name: "cms-sdk"
});
app.component('Treeselect', Treeselect);
app.component('Notify', Notify);

app.use(router);
app.config.globalProperties.$store = store;
app.mount("#app");
