<template>
    <div>
        <div v-if="showNotice && !messErr"
            class="text-white px-6 py-4 border-0 rounded relative mb-4 bg-emerald-500 main-noti">
            <span class="inline-block align-middle mr-8">
                <b class="capitalize"></b>{{ messageNotice }}
            </span>
        </div>

        <div v-if="showNotice && messErr" class="text-white px-6 py-4 border-0 rounded relative mb-4 bg-red-500 main-noti">
            <span class="inline-block align-middle mr-8">
                <b class="capitalize"></b>{{ messErr }}
            </span>
        </div>
    </div>
</template>
<style>
.main-noti {
    z-index: 9999;
    top: 0px;
    width: 20%;
    margin: auto;
    position: fixed!important;
    right: 30px;
}
</style>
<script>

export default {
    props: {
        showNotice: {
            type: Boolean, // kiểu dữ liệu của prop
            default: false, // giá trị mặc định nếu prop không được truyền vào
        },
        messageNotice: {
            type: String, // kiểu dữ liệu của prop
            default: ''
        },
        messErr: {
            type: Boolean, // kiểu dữ liệu của prop
            default: false,// kiểu dữ liệu của prop
        },
    },
    components: {
        //   CardStats,
    },
};
</script>
  