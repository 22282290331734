// router.js
import { createWebHistory, createRouter } from "vue-router";
import Admin from "@/layouts/Admin.vue";
import Auth from "@/layouts/Auth.vue";
import ManagerMerchant from "@/layouts/ManagerMerchant.vue";
import Dashboard from "@/views/admin/Dashboard.vue";
import Settings from "@/views/admin/Settings.vue";
import Tables from "@/views/admin/Tables.vue";
import merchantManager from "@/views/merchantManager/Index.vue";
import formMerchant from "@/views/merchantManager/formMerchant.vue";
import Login from "@/views/auth/Login.vue";
import Register from "@/views/auth/Register.vue";
import NotFound from "@/components/page404.vue";
import authMiddleware from './middleware/auth';
import account from "@/views/account/Index.vue";
import formAdmin from "@/views/account/formAdmin.vue";
import BaseLayout from "@/layouts/BaseLayout.vue";
import reportMerchant from "@/views/report/merchant.vue";
import CreateFee from "@/views/fees/Create.vue";
import UpdateFee from "@/views/fees/Update.vue";
import ListFee from "@/views/fees/List.vue";

const routes = [
  {
    path: "/",
    redirect: "/admin/dashboard",
    component: Admin,
    meta: {
      middleware: authMiddleware, // Truyền middleware vào meta
      loggedIn: false, // Giá trị của biến loggedIn
    },
    children: [
      {
        path: "/admin/dashboard",
        name: "dashboard",
        component: Dashboard,
      },
      {
        path: "/admin/settings",
        component: Settings,
      },
      {
        path: "/admin/tables",
        component: Tables,
      },
      // {
      //   path: "/admin/account",
      //   component: account,
      // },
    ],
  },
  {
    path: "/admin/merchantManager",
    redirect: "/admin/merchantManager/index",
    component: ManagerMerchant,
    meta: {
      middleware: authMiddleware, // Truyền middleware vào meta
    },
    children: [
      {
        path: "/admin/merchantManager/index",
        component: merchantManager,
        meta: { requiresAuth: true },
      },
      {
        path: "/admin/merchantManager/add",
        component: formMerchant,
        meta: { requiresAuth: true },
      },
      {
        path: "/admin/merchantManager/edit/:id",
        component: Register,
        meta: { requiresAuth: true },
      },
    ],
  }, {
    path: "/admin/config-fee",
    redirect: "/admin/config-fee/index",
    component: BaseLayout,
    meta: {
      middleware: authMiddleware,
    },
    children: [
      {
        path: "/admin/config-fee/index",
        component: ListFee,
        name: 'admin-config-fee',
        meta: { requiresAuth: true },
      }, {
        path: "/admin/config-fee/create",
        component: CreateFee,
        meta: { requiresAuth: true },
      }, {
        path: "/admin/config-fee/:id",
        component: UpdateFee,
        meta: { requiresAuth: true },
      },
    ],
  }, {
    path: "/admin/account",
    redirect: "/admin/account/index",
    component: ManagerMerchant,
    meta: {
      middleware: authMiddleware, // Truyền middleware vào meta
      // loggedIn: false, // Giá trị của biến loggedIn
    },
    children: [
      {
        path: "/admin/account/index",
        component: account,
        meta: { requiresAuth: true },
      },
      {
        path: "/admin/account/add",
        component: formAdmin,
        meta: { requiresAuth: true },
      },
      {
        path: "/admin/account/edit/:id",
        component: Register,
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: "/admin/report/transaction",
    redirect: "/admin/report/merchant",
    component: ManagerMerchant,
    meta: {
      // middleware: authMiddleware, // Truyền middleware vào meta
    },
    children: [
      {
        path: "/admin/report/transaction",
        component: reportMerchant,
        // meta: { requiresAuth: true },
      },
    ],
  },



  {
    path: "/auth",
    redirect: "/auth/login",
    component: Auth,
    children: [
      {
        path: "/auth/login",
        component: Login,
      },
      {
        path: "/auth/register",
        component: Register,
      },
    ],
  },
  {
    path: "/:catchAll(.*)",
    component: NotFound,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// Thêm middleware cho từng định tuyến
router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {
    const middleware = to.meta.middleware;
    middleware(next);
  } else {
    next();
  }
});

export default router;
