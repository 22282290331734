<template>
  <ul class="md:flex-col md:min-w-full flex flex-col list-none">
    <li class="items-center relative" v-bind:key="key" v-for="(menu_item, key) in navigation_menu">
      <router-link
          :to="menu_item.link"
          @click="toggleCollapse($event, menu_item)"
          :class="(menu_item.active ? 'router-link-active' : '') + ' text-xs uppercase py-3 font-bold block w-90'"
      >
        <i
            :class="' mr-2 text-sm ' + menu_item.icon"
        ></i>
        {{ menu_item.name }}

        <div class="absolute right-0" style="top: 10px" v-if="menu_item?.children?.length > 0">
          <svg v-if="menu_item?.active" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6" style="width: 25px;">
            <path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
          </svg>
          <svg v-else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6" style="width: 25px;">
            <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
          </svg>
        </div>
      </router-link>
      <div class="ml-3" v-if="menu_item?.children?.length > 0 && menu_item?.active">
        <ul class="md:flex-col md:min-w-full flex flex-col list-none">
          <li class="items-center" v-bind:key="sub_key" v-for="(sub_menu_item, sub_key) in menu_item?.children">
            <router-link
                :to="sub_menu_item.link"
                :class="(sub_menu_item.active ? 'router-link-active' : '') + ' text-xs uppercase py-3 font-bold block w-90'"
            >
              <i
                  :class="' mr-2 text-sm ' + sub_menu_item.icon"
              ></i>
              {{ sub_menu_item.name }}
            </router-link>
          </li>
        </ul>
      </div>
    </li>
  </ul>
</template>
<style>
.router-link-active {
  --tw-text-opacity: 1;
  color: rgba(16, 185, 129, var(--tw-text-opacity));
}
</style>

<script>
import ManuConfig from "@/config/menu-navbar";

export default {
  data() {
    return {
      navigation_menu: [],
    };
  },
  created() {
    this.navigation_menu = ManuConfig.map((item) => {
      if (item.children?.length > 0) {
        item.active = (window.location.href).includes(item.link);
      }
      return item;
    })
  },
  methods: {
    toggleCollapse: function (event, menuActive) {
      if (menuActive?.children?.length > 0) {
        event.preventDefault();
      }
      menuActive.active = !(menuActive?.active || false);
    },
  }
};
</script>
