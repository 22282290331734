<template>
  <div v-if="visible" id="alert_block_wrap" class="text-white px-6 py-4 border-0 rounded relative mb-4" :class="matchClass()">
    <span class="text-xl inline-block mr-5 align-middle">
      <i class="fas fa-bell"></i>
    </span>
    <span class="inline-block align-middle mr-4">
        <b class="capitalize mr-3 ml-1">{{ matchTitle() }}</b> {{ message }}
    </span>
    <button @click="closeAlert" class="absolute bg-transparent text-2xl font-semibold leading-none top-0 mt-4 mr-6 outline-none focus:outline-none" style="right: 15px;">
        <span>×</span>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      required: true
    },
    type: {
      type: String,
      default: 'info' // Các loại alert: 'info', 'success', 'warning', 'error'
    },
    duration: {
      type: Number,
      default: 300000 // Thời gian tự động tắt (ms), mặc định là 3 giây
    }
  },
  data() {
    return {
      visible: true
    };
  },
  watch: {
    message() {
      this.resetTimer();
    }
  },
  mounted() {
    this.startTimer();
  },
  methods: {
    startTimer() {
      this.timer = setTimeout(this.closeAlert, this.duration);
    },
    resetTimer() {
      clearTimeout(this.timer);
      this.visible = true;
      this.startTimer();
    },
    closeAlert() {
      this.visible = false;
    },
    matchClass() {
      switch (this.type) {
        case 'success':
          return 'bg-emerald-500'
        case 'error':
          return 'bg-red-500'
        case 'warning':
          return 'bg-emerald-500'
        case 'info':
          return 'bg-blueGray-500'
      }
    },
    matchTitle() {
      switch (this.type) {
        case 'success':
          return 'Thành công'
        case 'error':
          return 'Lỗi'
        case 'warning':
          return 'Cảnh báo'
        case 'info':
          return 'Thông báo'
      }
    },
  },
  beforeUnmount() {
    clearTimeout(this.timer);
  }
};
</script>
