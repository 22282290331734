// store.js
import { reactive, toRefs } from 'vue';

const state = reactive({
  isAuthenticated: false,
});

const mutations = {
  login() {
    state.isAuthenticated = true;
    console.log(state.isAuthenticated)
  },
  logout() {
    state.isAuthenticated = false;
  },
};

export default {
  ...toRefs(state),
  mutations,
};
