export default [
    {
        id: 1,
        label: 'Thanh toán QR'
    },{
        id: 2,
        label: 'Nạp tiền'
    },{
        id: 3,
        label: 'Rút tiền'
    },{
        id: 4,
        label: 'Chuyển tiền'
    },{
        id: 5,
        label: 'Thanh toán qua chuyển khoản (Ví)'
    },{
        id: 6,
        label: 'Thanh toán thẻ ATM'
    },{
        id: 7,
        label: 'Thanh toán nguồn tiền Ví'
    },{
        id: 8,
        label: 'Thanh toán thẻ quốc tế'
    },{
        id: 9,
        label: 'Thanh toán chuyển khoản (Cổng)'
    },{
        id: 10,
        label: 'Thanh toán qua liên kết Napas'
    },{
        id: 11,
        label: 'Thanh toán qua liên kết trực tiếp'
    },
]
