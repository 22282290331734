<template>
  <div>
    <div class="flex flex-wrap mt-4 table-main">
      <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white p-5">
        <div class="rounded-t mb-4 px-4 py-3 border-0">
          <div class="flex flex-wrap items-center" style="justify-content: space-between;">
            <div class="relative px-4 max-w-full">
              <h3 class="font-semibold text-lg text-blueGray-700">
                Config chi phí
              </h3>
            </div>
          </div>
        </div>
        <div class="bg-white block w-full overflow-x-autoflex-auto px-4 lg:px-10 py-10 pt-0">
          <alert v-if="alertMessage" :message="alertMessage" :type="alertType"></alert>
          <form-config-fee @submit-form="handleStoreConfig"></form-config-fee>
        </div>
        <div class="flex flex-wrap mt-4 table-main action_button_form_fee">
          <button type="submit" form="form_config_fee" class="py-2 px-8 bg-emerald-500 hover:bg-emerald-600 text-white font-semibold rounded-lg shadow-md focus:outline-none">Lưu</button>
          <button @click="reload" type="button" form="form_config_fee" class="py-2 px-8 bg-lightBlue-500 hover:bg-lightBlue-600 text-white font-semibold rounded-lg shadow-md focus:outline-none">Làm mới</button>
          <button @click="cancelAction" type="button" form="form_config_fee" class="py-2 px-8 bg-red-500 hover:bg-red-600 text-white font-semibold rounded-lg shadow-md focus:outline-none">Huỷ</button>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.table-main table tbody {
  font-size: 22px;
  font-weight: 500;
}
</style>

<script>

import FormConfigFee from "@/components/Fee/FormConfigFee.vue";
import apiClient from '@/services/api.js';
import Alert from "@/components/Alerts/Alert.vue"

export default {
  data() {
    return {
      alertMessage: '',
      alertType: 'info'
    };
  },
  components: {
    FormConfigFee,
    Alert,
  },
  props: {
    //
  },
  created() {
    //
  },
  watch: {
    //
  },
  methods: {
    async handleStoreConfig(configData) {
        try {
          this.alertMessage = '';
          let response = await apiClient.post('api/fee', {
            ...configData
          });
          if (response.data.status) {
            this.alertMessage = response.data.message;
            this.alertType = 'success';
            window.scroll({
              top: 10,
              left: 10,
              behavior: "smooth",
            });
            let self = this;
            setTimeout(function () {
              self.$router.push({ name: 'admin-config-fee' });
            }, 500);
          } else {
            this.alertMessage = response.data.message || 'Thêm mới thất bại. Vui lòng thử lại sau!';
            this.alertType = 'error';
            window.scroll({
              top: 10,
              left: 10,
              behavior: "smooth",
            });
          }
        } catch (e) {
          console.log(e)
        }
      },
    backToList() {
      this.$router.push({ name: 'admin-config-fee' });
    },
    cancelAction() {
      if (confirm('Config sẽ không được lưu nếu bạn rời khỏi trang!')) {
        this.backToList();
      }
    },
    reload() {
      location.reload()
    }
  },
};
</script>
