import apiClient from "@/services/api.js";
export default function authMiddleware(next) {
  const email = localStorage.getItem("email");
  apiClient.post("/auth/verify", { email }).then((response) => {
    if (response && !response.data.error_code) {
        next();
    }else{
        next("/auth/login");
    }
  });
}
