export default [
    {
        'name': 'Dashboard',
        'link': '/admin/dashboard',
        'icon': 'fas fa-tv'
    }, {
    'name': 'Quản lý authent merchant',
    'link': '/admin/merchantManager',
    'icon': 'fas fa-user'
    }, {
        'name': 'Báo cáo phí',
        'link': '/admin/config-fee',
        'icon': 'fas fa-tools',
        'children': [
            {
                'name': 'Config Phí',
                'link': '/admin/config-fee/index',
                'icon': 'fas fa-dot-circle',
            }
        ]
    }, {
        'name': 'Thống kê giao dịch theo Merchant',
        'link': '/admin/report/transaction',
        'icon': 'fas fa-user'
    },{
        'name': 'Quản lý Quyền',
        'link': '/admin/account',
        'icon': 'fas fa-tools'
    },
]
